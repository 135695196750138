@import "src/styles/vars_mixins";

.announcement-container {
  position: fixed;
  z-index: 100;
  right: 2rem;
  bottom: 2rem;
  width: 500px;
  border-radius: 1rem;
  transition: .3s ease-in-out;

  @include media-breakpoint-down(sm) {
    max-width: calc(360px - 0.5rem);
    width: 100%;
    right: 0.5rem;
    bottom: 0.5rem;
  }
}

.feedback-announcement {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem;
  border-radius: 6px;
  margin-top: 5px;
  overflow: auto;
  box-shadow: 0 6px 20px rgb(0, 0, 0, 0.15);
  position: relative;

  @include themify(day) {
    @apply bg-white;
  }

  @include themify(night) {
    @apply bg-dark-200;
  }
}

.announcement-title {
  font-size: 1.5rem;
  font-weight: bold;

  @apply text-gray-charcoal;

  @include themify(day) {
    @apply text-gray-charcoal;
  }

  @include themify(night) {
    @apply text-silver-010;
  }

  @media (max-width: 580px) {
    font-size: 1.2rem;
  }
}

.announcement-message {
  line-height: 1.5;

  @media (max-width: 580px) {
    font-size: 0.9rem;
  }

  p {
    @include themify(day) {
      @apply text-silver-035;
    }

    @include themify(night) {
      @apply text-silver-010;
    }

  }
}

.feedback-announcement {
  .row {
    display: flex;

    @media (max-width: 390px) {
      flex-wrap: wrap;
    }

  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .actions {
    gap: 0.5rem;
  }

  .close-btn {
    position: absolute;
    top: 7px;
    right: 3px;
  }
}